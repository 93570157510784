import React from 'react'

const toggle = props => (
  <button className="togggle-button" onClick={props.click}>
    <div className="toggle-button-line"/>
    <div className="toggle-button-line"/>
    <div className="toggle-button-line"/>
  </button>
);

export default toggle;
