import React from 'react'
import { Link } from 'gatsby'
import Toggle from '../SideDrawer/toggle'
//import logo from '../../images/gatsby-icon.png'

const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar-navigation">
      <div className="toolbar-toggle-button">
        <Toggle click={props.drawerClickHandler}/>
      </div>
      <div className="toolbar-logo">
        <Link>
          Nemando
        </Link>
      </div>
      <div className="space"/>
      <div className="toolbar-navigation-items">
        <ul>
          <li><Link>Heim</Link></li>
          <li><Link>Something</Link></li>
          <li><Link>Something</Link></li>
          <li><Link>Something</Link></li>
        </ul>
      </div>
    </nav>
  </header>

);

export default toolbar;
