import React from 'react'
import { Link } from 'gatsby'

const sidedrawer = props => {
  let drawerClasses = ['side-drawer'];
  if (props.show) {
    drawerClasses = ['side-drawer open'];
  }
  return (
    <nav className={drawerClasses}>
      <ul>
        <li><Link>Heim</Link></li>
        <li><Link>something</Link></li>
        <li><Link>something</Link></li>
        <li><Link>something</Link></li>
      </ul>
    </nav>
  );
};
export default sidedrawer;
