import { Link } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faCircle, faPhone, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'


class Footer extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <Container>
          <Row>
            <Col md="12" lg={{ size: 4, order: 2, offset: 1 }}>
              <div className="logo">
                <h2>nemando</h2>
              </div>
              <div className="footer-address">
                <ul>
                  <li>
                    <h5>Radiusbacken 21, 12629 Stockholm</h5>
                  </li>
                  <li>
                    <h5><FontAwesomeIcon icon={faPhone} color="#fe6663"/>+46 8-559 251 20</h5>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="12" lg={{ size: '6', order: 2, offset: 0 }}>
              <div className="footer-icons">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} color="white" size="4x" />
                  <FontAwesomeIcon icon={faLinkedinIn} color="#fe6663" transform="shrink-6" size="3x" />
                </span>
                <span className="fa-layers fa-fw">
                 <FontAwesomeIcon icon={faCircle} color="white" size="4x"/>
                 <FontAwesomeIcon icon={faFacebookF} color="#fe6663" transform="shrink-6" size="3x"/>
                </span>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} color="white" size="4x"/>
                  <FontAwesomeIcon icon={faInstagram} color="#fe6663" transform="shrink-6" size="3x"/>
                </span>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} color="white" size="4x"/>
                  <FontAwesomeIcon icon={faTwitter} color="#fe6663" transform="shrink-6" size="3x"/>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg={{ size: 'auto', offset: 1 }}>
              <div className="footer-info">
                <Link to="/">Kennitala: 060889-2609</Link>
              </div>
            </Col>
            <Col md="12" lg="2">
              <div className="footer-info">
                <Link to="/">VSK-númer: 06088</Link>
              </div>
            </Col>
            <Col md="12" lg={{ size: 'auto'}}>
              <div className="footer-links">
                <Link to="/">Persónuverndarstefna</Link>
              </div>
              <div className="vertical-border"/>
            </Col>
            <Col md="12" lg={{ size: 'auto'}}>
              <div className="footer-links">
                <Link to="/">Öryggisstefna</Link>
              </div>
              <div className="vertical-border"/>
            </Col>
            <Col md="12" lg={{ size: 'auto'}}>
              <div className="footer-links">
                <Link to="/">Skilmálar þjónustu</Link>
              </div>
              <div className="vertical-border"/>
            </Col>
            <Col md="12" lg={{ size: 'auto'}}>
              <div className="footer-links">
                <Link to="/"><FontAwesomeIcon icon={faEnvelope} className="link-icon" />Vefpóstur</Link>
              </div>
              <div className="vertical-border"/>
            </Col>
            <Col md="12" lg={{ size: 'auto'}} >
              <div className="footer-links">
                <Link to="/"><FontAwesomeIcon icon={faGlobe} className="link-icon"/>English</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Footer
